<template>
  <div class="d-flex w-100 h-100 flex-row">
    <div
      class="d-flex flex-column wrapper align-items-end text-start bg-main-cyan"
    >
      <div class="d-flex flex-column p-5">
        <h4 class="my-5">
          Your Digital
          <br />
          Assesment Assistant
        </h4>
      </div>
      <div class="wrapper-img mt-auto">
        <img src="@/assets/img/login-page-xray-img.png" alt="xray-image" />
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-center w-100 bg-white"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginLayout',
}
</script>

<style lang="scss">
.wrapper {
  max-width: 433px;
  color: white;
}
</style>
